.bannerText {
    font-size: 2vw;
    font-weight: 900;    
    letter-spacing: 4vw;
    line-height: 1.5em;    
}

.bannerAsterix {
    font-size: 2vw;
    font-weight: 900;    
    line-height: 1.5em;    
}

.banner {
    text-align: center;
}

.container {
    border: 1px solid black;
}

.link {
    text-decoration: none;
    color: black;
    font-weight: 900;
}

.drawer {
    background-color: #D4D0C5;
    height: 100%;
}